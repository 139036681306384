import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Environment } from '../../environments/environment';
import { IRestResponse } from '../core/interface/response.interface';
import { IChangePwdPayload, IForgotPwdPayload, ISetPwdPayload } from '../interfaces/user.interface';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private restService: RestService, private router: Router) { }

  public changePassword(payload: IChangePwdPayload): Observable<IRestResponse<any>> {
    return this.restService
      .put(
        Environment.UrlPath + `/user/change/password`,
        payload,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public setPassword(payload: ISetPwdPayload): Observable<IRestResponse<any>> {
    return this.restService
      .put(
        Environment.UrlPath + `/user/setPassword`,
        payload,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
  public forgotPassword(payload: IForgotPwdPayload): Observable<IRestResponse<any>> {
    return this.restService
      .put(
        Environment.UrlPath + `/user/forgotPassword`,
        payload,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public isWabaVerified(): Observable<boolean> {
    return this.restService.get(Environment.UrlPath + `/company/user/WABA`, null, true)
      .pipe(
        map((res: any) => {
          if (res && res.response) {
            return true
          } else {
            this.router.navigate(['waba-verification'])
            return false
          }
        }),
        catchError((error) => throwError(error)),
      )
  }

}
